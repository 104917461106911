'use strict';

/** Generates add_to_cart event tracking datas from button html data attributes */

const generateAddToCartTrackingDatasFromBtnDataAttr = $btn => {
  const addToCartParams = {
    event: 'add_to_cart',
    sub_event: 'add_to_cart_accomodation',
    action_with_services_infos: false,
    click_type: 'click',
    ga_label: $btn.text().trim(),
    ga_category: PAGE_SCRIPTS.page_template,
    ga_value: 1,
    ecommerce: {
      items: []
    }
  };

  if ($btn.get(0).hasAttribute('data-tracking')) {
    const trackingDatas = JSON.parse($btn.attr('data-tracking'));
    if (Object.prototype.hasOwnProperty.call(trackingDatas, 'clickCode')) {
      addToCartParams.click_code = trackingDatas.clickCode;
    }
    if (Object.prototype.hasOwnProperty.call(trackingDatas, 'gaAction')) {
      addToCartParams.ga_action = trackingDatas.gaAction;
    }
    if (Object.prototype.hasOwnProperty.call(trackingDatas, 'gaLabel')) {
      addToCartParams.ga_label = trackingDatas.gaLabel;
    }
  }

  if ($btn.length) {
    const startDate = $btn.attr('data-start');
    const m_startDate = genericDate.toDisplay(startDate);
    const endDate = $btn.attr('data-end');
    const housingTypeName = $btn.attr('data-housing');
    const comfortLevelName = $btn.attr('data-comfort');
    const accommodationDatas = {
      actionCode: {
        code: $btn.attr('data-actionCodeCode'),
        name: $btn.attr('data-actionCodeName')
      },
      housing: {
        name: $btn.attr('data-housingName'),
        code: $btn.attr('data-housingCode'),
        comfortLevel: comfortLevelName !== '' ? {
          name: comfortLevelName
        } : null
      },
      cache: {
        price: {
          original: {
            value: $btn.attr('data-priceOriginal')
          },
          promo: {
            value: $btn.attr('data-pricePromo')
          },
          discount: $btn.attr('data-priceDiscount')
        },
        offerCode: $btn.attr('data-code'),
        stock: $btn.attr('data-stock'),
        maxPersons: $btn.attr('data-maxcapacity')
      },
      brand: {
        code: 'CP'
      },
      destination: {
        code: $btn.attr('data-destinationCode')
      },
      country: {
        name: $btn.attr('data-countryName'),
        code: $btn.attr('data-countryCode')
      },
      offer: {
        name: $btn.attr('data-offer')
      },
      region: {
        code: $btn.attr('data-regionCode')
      },
      additionnal_item_infos: {
        item_has_booking_infos: true,
        item_booking_adults_number: parseInt($btn.attr('data-adultsNb')),
        item_booking_children_age: $btn.attr('data-ages') !== '' ? $btn.attr('data-ages') : '[]',
        item_booking_children_number: parseInt($btn.attr('data-childrenNb')),
        item_booking_end_date: endDate,
        item_booking_seniors_number: parseInt($btn.attr('data-seniorsNb')),
        item_booking_guests_number: parseInt($btn.attr('data-guests')),
        item_booking_pets_number: parseInt($btn.attr('data-petsNb')),
        item_booking_offer_amount_ati: parseFloat($btn.attr('data-priceOriginal')),
        item_booking_start_date: startDate,
        item_booking_has_id: false,
        item_booking_stay: '',
        item_booking_start_day_of_week: new Date(m_startDate).toLocaleDateString(undefined, {weekday: 'long'}).replace(/\./gi, ''),
        item_booking_nights_number: genericDate.diff(new Date(m_startDate), new Date(endDate))
      }
    };

    if (housingTypeName !== '') {
      accommodationDatas.housing.housingType = {
        name: housingTypeName
      };
    }

    addToCartParams.ecommerce.items = Tracking.generateAccommodationsDatas({
      datas: [accommodationDatas]
    });
  }

  return addToCartParams;
};

export {generateAddToCartTrackingDatasFromBtnDataAttr};