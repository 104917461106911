import {generateAddToCartTrackingDatasFromBtnDataAttr} from '../../components/tracking/_generateAddToCartTrackingDatasFromBtnDataAttr';

$(() => {
  dispatcher.on('tracking:triggerProductViewItem', triggerProductItemViewEvent);

  /** Init local code related to GTM (click events, add_to_cart, ...) */
  dispatcher.on('tracking:trackingLink', trackingLink);
  $(document).on('click.goToEresa', '.js-goToEresa', e => {
    /** Click events */
    Tracking.dataLayerPush(generateAddToCartTrackingDatasFromBtnDataAttr($(e.currentTarget)));
  }).on('click', '.js-Tracking--link', e => {
    /** add_to_cart event */
    trackingLink(e);
  });

  /** Tant que le GTM_id n'a pas été défini, on ne fait rien */
  const gtmIdDetection = setInterval(() => {
    /** Si GTM_id n'a pas été défini, on ne fait rien */
    if (typeof GTM_id === 'string' && typeof Tracking !== 'undefined') {

      /** Initialisation du script GTM */
      Tracking.launchGTM(GTM_id);

      /** Global page_view event initialisation */
      if (typeof TRACKING_PAGEVIEW === 'object') {
        TRACKING_PAGEVIEW.page_template = PAGE_SCRIPTS.page_template;
        TRACKING_PAGEVIEW.page_name = PAGE_SCRIPTS.page_name;
        TRACKING_PAGEVIEW.currency = GLOBAL_SCRIPTS.CURRENCY_CODE;
        TRACKING_PAGEVIEW.language = GLOBAL_SCRIPTS.MARKET_LANGUAGE.toLowerCase();
        TRACKING_PAGEVIEW.site_country = GLOBAL_SCRIPTS.MARKET_CODE.toUpperCase();
        TRACKING_PAGEVIEW.page_shrturl = PAGE_SCRIPTS.page_shrturl;
        TRACKING_PAGEVIEW.multicottage = cookieManagement.getCookie('storedSearchParams') !== null && typeof JSON.parse(cookieManagement.getCookie('storedSearchParams')).multiparticipants !== 'undefined' && JSON.parse(cookieManagement.getCookie('storedSearchParams')).multiparticipants.length > 1 ? 'yes' : 'no';
        Tracking.triggerPageView(TRACKING_PAGEVIEW);
      }

      /** Remontée du produit sur les FP/Cottage details (event view_item:product_detail) */
      triggerProductItemViewEvent();

      /** Remontée des services (event view_item_list:view_services) */
      if (typeof trackServiceItems !== 'undefined' && trackServiceItems.length) {
        trackServiceItems.forEach((trackServiceItem, index) => {
          trackServiceItem.index = index + 1;
        });
        Tracking.dataLayerPush({
          event: 'view_item_list',
          sub_event: 'view_services',
          ecommerce: {
            items: trackServiceItems
          }
        });
      }

      /** Remontée des activités (event view_item_list:view_activities) */
      if (typeof trackActivityItems !== 'undefined' && trackActivityItems.length) {
        trackActivityItems.forEach((trackActivityItem, index) => {
          trackActivityItem.index = index + 1;
        });
        Tracking.dataLayerPush({
          event: 'view_item_list',
          sub_event: 'view_activities',
          ecommerce: {
            items: trackActivityItems
          }
        });
      }

      /** Remontée des accommodations (event view_item_list:view_accommodations) */
      if (typeof TRACKING_accomm_view_item_list !== 'undefined' && TRACKING_accomm_view_item_list.length) {
        TRACKING_accomm_view_item_list.forEach(trackAccommList => {
          if (trackAccommList.datas.length) {
            trackAccommList.datas.forEach(trackingDatas => {
              const additionnal_infos = {};

              if (typeof trackingDatas.cache.date === 'string') {
                additionnal_infos.item_start_date = trackingDatas.cache.date;
                additionnal_infos.item_start_day_of_week = new Date(trackingDatas.cache.date).toLocaleDateString(undefined, {weekday: 'long'}).replace(/\./gi, '');

                if (typeof trackingDatas.cache.dateEnd === 'string') {
                  additionnal_infos.item_length_of_stay = genericDate.diff(new Date(trackingDatas.cache.date), new Date(trackingDatas.cache.dateEnd));
                }
              }

              if (typeof trackingDatas.cache.dateEnd === 'string') {
                additionnal_infos.item_end_date = trackingDatas.cache.dateEnd;
              }

              if (Object.keys(additionnal_infos).length) {
                trackingDatas.additionnal_item_infos = additionnal_infos;
              }
            });

            Tracking.dataLayerPush({
              event: 'view_item_list',
              sub_event: 'view_accommodations',
              ecommerce: {
                items: Tracking.generateAccommodationsDatas({
                  datas: trackAccommList.datas
                })
              }
            });
          }
        });
      }

      clearInterval(gtmIdDetection);
    }
  }, 250);
});

const trackingLink = e => {
  const datas = {};

  $.each($(e.currentTarget).data('tracking'), (key, value) => {
    if (key === 'activityCode') {
      //collecting activity data
      if (typeof trackActivityItems !== 'undefined') {
        for (let trackActivityItem of trackActivityItems) {
          if (Object.prototype.hasOwnProperty.call(trackActivityItem, 'item_sku')) {
            if (trackActivityItem.item_sku === value) {
              datas.actionWithActivitiesInfos = true;
              datas.activitiesInfos = ['trackingData_activity_' + value];
              break;
            }
          }
        }
      }
    } else {
      datas[key] = value;
    }
  });

  if (!$.isEmptyObject(datas)) {
    if (!Object.prototype.hasOwnProperty.call(datas, 'event')) {//on peut dorénavant forcer l'event dans le tracking envoyé, en l'ajoutant dans les data du tracking
      datas.event = Object.prototype.hasOwnProperty.call(datas, 'popinName') ? 'popinDisplay' : 'clickEvent';//specific value for the trackingLink function
    }

    Tracking.dataLayerPush(datas);
  }
};

const triggerProductItemViewEvent = () => {
  if (typeof product_view_item === 'object') {
    if (product_view_item.cache.date !== null) {
      product_view_item.additionnal_item_infos.item_start_day_of_week = new Date(product_view_item.cache.date).toLocaleDateString(undefined, {weekday: 'long'}).replace(/\./gi, '');
      product_view_item.additionnal_item_infos.item_length_of_stay = genericDate.diff(new Date(product_view_item.cache.date), new Date(product_view_item.cache.dateEnd));
    }

    //-- Booking Upgrade specifics
    const urlParams = window.location.search;
    const isUpgradeCapacity = urlParams.indexOf('isUpgradeCapacity') !== -1;
    const isUpgradeComfort = urlParams.indexOf('isUpgradeComfort') !== -1;
    if (isUpgradeCapacity || isUpgradeComfort) {
      product_view_item.additionnal_item_infos.item_is_overbooking = true;
      product_view_item.additionnal_item_infos.item_overbooking_type = isUpgradeCapacity && isUpgradeComfort ? 'niveau de confort et nbre de chambre' : isUpgradeCapacity ? 'nbre chambre superieur' : isUpgradeComfort ? 'niveau de confort superieur' : '';
    }

    Tracking.dataLayerPush({
      event: 'view_item',
      sub_event: 'product_detail',
      page_product_view: $('body').hasClass('domain') ? 1 : 0,
      ecommerce: {
        items: Tracking.generateAccommodationsDatas({
          datas: [product_view_item]
        })
      }
    });
  }
};
